<template>
  <div class="login">
    <div class="login__logo">
      <img src="../assets/icons/default/logo_main.svg" alt="" />
      <div class="login__logo-text">The One System</div>
    </div>

    <div class="login__links login__links--top">
      <div class="login__link">
        <div class="login__link-icon login__link-icon--register"></div>
        <span>register</span>
      </div>
    </div>

    <div class="login__links login__links--bottom"> 
      <div class="login__link login__link--regular">+41 71 554 51 55</div>
      <div class="login__link login__link--regular">
        support@theonesystem.ch
      </div>
      <div class="login__link login__link--regular">www.theonesystem.ch</div>
      <div class="login__link login__link--made">
        © 2024, made with
        <img
          class="login__heart"
          src="../assets/icons/default/heart.svg"
          alt=""
        />
        <a style="color: white;" href="https://www.adits.ch/">by Adits</a>
      </div>
    </div>

    <div class="login__text">
      fast <br />
      flexible <br />
      easy
    </div>

    <!-- <div class="colorpicker">
      <div class="colorpicker__title">Choose color</div>
      <div class="colorpicker__colors">
        <div class="colorpicker__color colorpicker__color--green"></div>
        <div class="colorpicker__color colorpicker__color--red"></div>
        <div class="colorpicker__color colorpicker__color--blue"></div>
      </div>
    </div> -->

    <form class="form" @submit.prevent="signIn()">
      <button type="submit" class="form__button">Sign in</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Lock",

  data() {
    return {};
  },

  methods: {
    signIn() {
      this.$router.push({
        name: "Dashboard",
      });
    },

    checkInput(event, type) {
      const { value } = event.target;
      if (type === "email")
        value ? (this.emailError = false) : (this.emailError = true);
      else if (type === "password")
        value ? (this.passwordError = false) : (this.passwordError = true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_login.scss";
</style>
