<template>
<div class="login" @mousemove="moveVideo($event)">
    <!-- <Loading v-if="loading" /> -->

    <video class="login__video" :style="{ left: `${moveX - 20}px`, top: `${moveY - 100}px` }" poster="../assets/images/login_poster_video.jpg" playsinline autoplay muted loop>
        <source src="../assets/idea_test2.mp4" type="video/mp4" />
    </video>

    <div class="login__logo">
        <img src="../assets/icons/default/logo_main.svg" alt="" />
        <div class="login__logo-text">The One System</div>
    </div>

    <div class="login__links login__links--top">
        <div class="login__link">
            <div class="login__link-icon login__link-icon--register"></div>
            <span>{{ $t(`login.${"signIn"}`) }}</span>
        </div>
    </div>

    <div class="login__links login__links--bottom">

        <div class="login__link login__link--regular">+41 71 554 51 55</div>
        <div class="login__link login__link--regular">
            support@theonesystem.ch
        </div>
        <div class="login__link login__link--regular">www.theonesystem.ch</div>
        <div class="login__link login__link--made">
            © 2024, made with
            <img class="login__heart" src="../assets/icons/default/heart.svg" alt="" />
            <a style="color: white;" href="https://www.adits.ch/">by Adits</a>
        </div>
    </div>

    <div class="login__text">
        fast <br />
        flexible <br />
        easy
    </div>

    <!-- <div class="colorpicker">
      <div class="colorpicker__title">Choose color</div>
      <div class="colorpicker__colors">
        <div class="colorpicker__color colorpicker__color--green"></div>
        <div class="colorpicker__color colorpicker__color--red"></div>
        <div class="colorpicker__color colorpicker__color--blue"></div>
      </div>
    </div> -->

    <form v-if="!showAuthModal" class="form" @submit.prevent="login()">
        <div class="socials">
            <div class="socials__title">{{ $t(`login.${"loginTitle"}`) }}</div>
            <div class="socials__socials">
                <div class="socials__social icon__awesome icon__awesome--fb"></div>
                <div class="socials__social icon__awesome icon__awesome--twitter"></div>
                <div class="socials__social icon__awesome icon__awesome--linked-in"></div>
            </div>
        </div>

        <div class="form__title">{{ $t(`login.${"enterDetails"}`) }}</div>

        <div class="form__input-wrapper">
            <input type="text" class="form__input" v-model="email" @input="checkInput($event, 'email')" :class="{ 'form__input--error': emailError }" />
            <div class="form__input-icon form__input-icon--mail"></div>
            <div class="form__placeholder" :class="{ 'form__placeholder--hide': email }">
                Email
            </div>
            <div class="form__bar"></div>
        </div>
        <div class="form__input-wrapper">
            <input :type="!showPassword ? 'password' : 'text'" class="form__input" v-model="password" @input="checkInput($event, 'password')" :class="{ 'form__input--error': passwordError }" />
            <div class="form__input-icon form__input-icon--password"></div>
            <div class="form__placeholder" :class="{ 'form__placeholder--hide': password }">
                Password...
            </div>
            <div class="form__bar"></div>
            <div class="form__visibility" @click="showPassword = !showPassword" :class="{ 'form__visibility--visible': showPassword }"></div>
        </div>
        <div class="form__tools">
            <div class="form__remember">
                <input class="form__checkbox" type="checkbox" id="remember" />
                <label for="remember" class="form__label"></label>
                <label for="remember">{{ $t(`login.${"rememberMe"}`) }}</label>
            </div>
            <!-- <div class="form__forgot">Forgot password?</div> -->
        </div>

        <div v-if="loading" class="form__button button button--default button--shadow button--loading"></div>
        <button v-else type="submit" class="form__button button button--default button--shadow">
            <span>{{ $t(`login.${"signIn"}`) }}</span>
        </button>
    </form>

    <form v-if="showAuthModal" class="form" @submit.prevent="sendCode()">
        <div class="socials">
            <div class="socials__title">The One System.</div>
            
            <div class="icon icon__authentiaction" style="margin: 5px auto;"></div>
            <div class="socials__title">{{ $t(`login.${"authenticateTitle"}`) }}</div> 
        </div>

        <div class="form__title">{{ $t(`login.${"enterCode"}`) }}</div>

        <div class="form__input-wrapper code-inputs-wrapper">
            <input 
                type="text" 
                maxlength="1" 
                inputmode="numeric" 
                class="form__input code-input" 
                :class="{ 'form__input--error': codeError }" 
                v-model="codes[index]" 
                :ref="'codeInput' + index"  
                v-for="(code, index) in codes" 
                :key="index" 
                @input="handleCodeInput($event, index)" 
                @keydown="handleCodeKeyDown($event, index)" 
                @paste="handleCodePaste" 
            />
        </div>

        <div class="form__title code-resend">
            <p>Didn't get the code? <span @click="resendCode()">Resend Code</span></p>
        </div>
        <div v-if="loading" class="form__button button button--default button--shadow button--loading"></div>
        <button v-else type="submit" class="form__button button button--default button--shadow">
            <span>{{ $t(`login.${"authenticateBtn"}`) }}</span>
        </button>
    </form>
</div>
</template>

<script>
// import Loading from "@/partials/Loading";
export default {
    name: "Login",

    data() {
        return {
            showAuthModal: false,
            codes: ["", "", "", "", "", ""],
            moveX: 0,
            moveY: 0,
            email: "",
            emailError: false,
            passwordError: false,
            password: "",
            loading: false,
            showPassword: false,

            codeError: false,
        };
    },

    components: {
        // Loading,
    },

    methods: { 
        handleCodeInput(event, index) {
            const value = event.target.value;

            if (isNaN(value)) {
                this.$set(this.codes, index, "");
                return;
            }

            if (value !== "") {
                const nextInput = event.target.nextElementSibling;
                if (nextInput) {
                    nextInput.focus();
                }
            }
        },

        handleCodeKeyDown(event, index) {
            const key = event.key.toLowerCase();

            if (key === "backspace") {
                const prevInput = event.target.previousElementSibling;
                if (prevInput && this.codes[index] === "") {
                    prevInput.focus();
                }
            }
        },

        handleCodePaste(event) {
            event.preventDefault();  
        
            const pastedData = (event.clipboardData || window.clipboardData).getData('text');
            const digits = pastedData.replace(/\D/g, '').split('');

        
            digits.forEach((digit, index) => {
                if (index < this.codes.length) {
                    const input = this.$refs[`codeInput${index}`][0];  
                    if (input) {
                        input.value = digit; 
                        this.codes[index] = digit;  
                    }
                }
            });
        },


        moveVideo(event) {
            this.moveX = (event.pageX * -1) / 70;
            this.moveY = (event.pageY * -1) / 70;
        },

        async login() {
            let email = this.email,
                password = this.password;

            !email ? (this.emailError = true) : (this.emailError = false);
            !password ? (this.passwordError = true) : (this.passwordError = false);

            if (email && password) {
                this.loading = true;
                await this.$store.dispatch("login", {
                    email,
                    password,
                });
                this.loading = false;
                this.showAuthModal = true;
                this.$root.$i18n.locale = localStorage.getItem("language");
            }
        },

        checkInput(event, type) {
            const {
                value
            } = event.target;
            if (type === "email")
                value ? (this.emailError = false) : (this.emailError = true);
            else if (type === "password")
                value ? (this.passwordError = false) : (this.passwordError = true);
        },

        async sendCode() { 
          if(this.codes.filter(code => code.trim() !== "").length == 6){
            let code = this.codes.join("");
            
            if (this.email) {
                let email = this.email;
                this.loading = true;
                await this.$store.dispatch("sendAuthCode", {
                    email,
                    code
                });
                this.loading = false;
            }else{
              const errorMessage = "Couldn't find email!"; 
 
              this.$store.commit("setToast", {
                display: true,
                type: "error",
                message: errorMessage,
              });
            }
          }else{
            const errorMessage = "The authentication code is incomplete!"; 
 
              this.$store.commit("setToast", {
                display: true,
                type: "error",
                message: errorMessage,
              });
          }
        },

        async resendCode() {
            if (this.email) {
                let email = this.email;
                this.loading = true;
                await this.$store.dispatch("resendAuthCode", {
                    email,
                });
                this.loading = false;
            }else{
              const errorMessage = "Couldn't find email!"; 
 
              this.$store.commit("setToast", {
                display: true,
                type: "error",
                message: errorMessage,
              });
            }
        }
    },

    mounted(){
        this.showAuthModal = false;
    }

}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_login.scss";

.login__link--made:hover a {
    text-decoration: underline;
}

.code-inputs-wrapper {
    display: flex !important;
    justify-content: space-between !important;
    width: 82% !important;

}

.code-input {
    width: 36px !important;
    border: 1px solid #B9C2CD  ;
    border-radius: 7px !important;
    margin: 0 !important;
    padding: 5px 10px !important;
    font-size: 25px !important;
    position: relative !important;

    &:focus {
        border: 1px solid #22DB8E !important;
    }
}

.code-resend {
    margin-top: 46px !important;
    margin-bottom: 0 !important;

    p {
        font-size: 12px !important;

        span {
            color: #22DB8E !important;
            cursor: pointer !important;
        }
    }
}
</style>
